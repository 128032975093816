import React from 'react'
import Link from 'gatsby-link'

import Section from '../components/Section';
import Sectionnew from '../components/Sectionnew';

import Wave from '../components/Wave';
import Testimonial from '../components/Testimonial';
import './services.css'

import styled from 'styled-components'
import '../components/contact.css'
import '../components/portfolio.css'
import Footer from '../components/footer'
import ReactDOM from 'react-dom'
import Navbar from '../components/Navbar/Navbar'
import Head from '../components/head'


const SectionCaption = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #94A4BA;
  text-align: center;
`

const Button = styled.button`
    background: linear-gradient(102.24deg, #ffcc00 0%, #ffcc00 100%);
    box-shadow: 0px 10px 20px rgba(101, 41, 255, 0.15);
    border-radius: 30px;
    color: white;
    border: none;
    padding: 16px 60px;
    
    
    font-weight: 600;
    font-size: 18px;
    justify-self: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
        box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
        transform: translateY(-3px);
    }
`

const SectionCellGroup = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  padding: 0 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`





const AboutPage = () => (

  
  <div>
    

    <Head title="About Tima's Flavours Catering Services" />

   
  
<Navbar />

<div className="containerflex">
  
      <div className="item">
        <p><span className="lighttext">About</span> Tima's Flavours.

</p>
      </div>

      

    
      <div className="item">
      <img  className="itemimg"  src='/images/tlogo.jpg'   />

      </div>

        <Wave />
     
    </div>
   
<div className="ag">
    <div className="hahomenn">
    <p>The kids call me Tima as it is easier for them, I’ve always been passionate about food, hosting parties even from the very young aga! I was born and raised in Paris, originally from Senegal i have been in the uk for 10 years! When i had my son i was inspired to finally do what i love the most, make people happy through my food!
</p>
    </div>

    <div className="hahomenn2">
    <p> I offer cooking classes to kids from 2 to 15 years of age, but also offer catering services for any special occasion! You craving a french brunch on Sunday morning I’m your girl, your kids birthday needs some entertainment, How about a cooking class for 90 minutes where they get to learn, being messy and eat all at the same time!
</p>
    </div>
    </div>

    <Sectionnew
      image='/images/newbg.png'
      title="Your number 1 friendly caterer."
      text="In the UK."
     />
 







   
 
   
       






   
    

  
<div className="Cards">
      <h4>CUSTOMER STORIES</h4>
      <h2>5 STARS ON GOOGLE</h2></div>
 
<div className="Testimonial">
      
     
      <Testimonial 
          title="We Hired Tima for our corporate event and the food was absolutely amazing as was the service! Highly recommended!"
          text=""
          author="Joshua"
          image='/images/josh.jpg' /> 
         
        <Testimonial 
          title="I absolutely recommend Tima to anyone who has an event that requires catering. I send my children to her cooking classes too!"
          text=""
          author="Elizabeth"
          image='/images/eli.jpg' />
             
    </div>
    <div className="Cardb">
    <Button>More Stories</Button>
</div>



     
    
     <Footer />
     
    
  </div>
)

export default AboutPage
